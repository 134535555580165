import React from 'react';
import get from 'lodash.get';
import HeroSlider from 'components/HeroSlider';
import { injectIntl } from 'gatsby-plugin-react-intl';
import {
    Spacing,
    SharedStyles,
    Container,
    GenericBenefitList,
    GenericProductList,
} from '../../../kenra-storybook/index';
import FAQAccordion from './FAQAccordion';
import BestSellerSlider from 'components/NewHome/BestSellerSlider';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import MasonaryItem from './MasonaryItem';
import { ga4Event } from 'helpers/ga4'

const { StMediumTitle, StPageTitle } = SharedStyles;

function parseSection3Fragments(fragments, intl) {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {};
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Title':
                    returnData['title'] = fragmentData.value;
                    break;
                case 'Subtitle':
                    returnData['subtitle'] = fragmentData.value;
                    break;
                default:
                    break;
            }
        });
        return {
            title: returnData.title,
            subtitle: returnData.subtitle,
            onToggle: (isOpen) => {
                ga4Event("TripleRepairFaqToggled", {question: returnData.title, open: isOpen})
                if (isOpen) { 
                    ga4Event("TripleRepairFaqOpened", {question: returnData.title})
                }
            }
        };
    });

    return fragmentDataMap;
}

function parseFragments(fragments, intl) {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {};
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value;
                    break;
                case 'HighlightText':
                    if (fragmentData.value !== null) {
                        returnData['footnoteText'] = fragmentData.value.replaceAll(
                            '\\n',
                            '\n'
                        );
                    }
                    break;
                case 'Title':
                    returnData['title'] = fragmentData.value;
                    if (fragmentData.value !== null) {
                        returnData['title'] = fragmentData.value.replaceAll(
                            '\\n',
                            '<br/>'
                        );
                    }
                    break;
                case 'Text':
                    returnData['text'] = fragmentData.value;
                    if (fragmentData.value !== null) {
                        returnData['text'] = fragmentData.value.replaceAll(
                            '\\n',
                            '\n'
                        );
                    }
                    break;
                case 'ItemColor':
                    returnData['color'] = fragmentData.value;
                    break;
                case 'ProductLink':
                    if (fragmentData.value) {
                        returnData['link'] = fragmentData.value;
                    }
                    break;
                default:
                    break;
            }
        });

        return {
            image: returnData.img,
            title: returnData.title,
            text: returnData.text,
            footnoteText: returnData.footnoteText,
            ctaLabel: returnData.color,
            link: returnData.link,
        };
    });

    return fragmentDataMap;
}

const TripleRepairLanding = ({
    intl,
    page,
    allShopifyProducts,
    allGoogleSheetProductsRow,
}) => {
    let section1Data = parseFragments(page.section1Data.fragments, null);
    let collectionData = parseFragments(page.collectionData.fragments, intl);
    let faqTitle = page?.moreCollectionTitle;
    let faqData = parseSection3Fragments(page.section3Data.fragments, intl);
    const { stylistFavoritesProducts } = page;

    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <HeroSlider slides={page.banner} />
            </Spacing>
            <Spacing>
                <Container>
                    <StPageTitle style={{ 'margin-bottom': '12px', 'text-transform': 'uppercase' }}>
                        <h1 style={{'fontSize': '28px', 'fontWeight': '600', 'letterSpacing': '2px'}}>{get(page, 'title')}</h1>
                    </StPageTitle>
                    <StMediumTitle
                        style={{
                            textTransform: 'unset',
                            fontWeight: 'normal',
                            display: 'flex',
                            justifyContent: 'center',
                            'align-items': 'center',
                            textAlign: 'center',
                            fontSize: '20px'
                        }}
                        dangerouslySetInnerHTML={{
                            __html: get(page, 'section1Subtitle'),
                        }}
                    ></StMediumTitle>
                </Container>
            </Spacing>
            <Spacing removeSpaceTop>
                <Container>
                    {section1Data && (
                        <GenericBenefitList
                            items={section1Data}
                            title=""
                            embedTitleAndImage="true"
                            bgImg={get(
                                page,
                                'section1Background.localFile.publicURL'
                            )}
                            col4
                        />
                    )}
                </Container>
            </Spacing>
            <Spacing removeSpaceTop>
                {collectionData && (
                    <ResponsiveMasonry
                        columnsCountBreakPoints={{ 1: 1, 768: 2 }}
                    >
                        <Masonry>
                            {collectionData.map((item, index) => (
                                <MasonaryItem
                                    key={index}
                                    index={index}
                                    {...item}
                                />
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                )}
            </Spacing>

            <Spacing>
                <FAQAccordion title={faqTitle} items={faqData} />
            </Spacing>

            {stylistFavoritesProducts && stylistFavoritesProducts.length > 0 && (
                <Spacing
                    removeSpaceBottom
                    style={{ backgroundColor: '#FFF', marginTop: '-15px' }}
                >
                    <BestSellerSlider
                        title={page.stylistFavoritesTitle}
                        subtitle={''}
                        products={page.stylistFavoritesProducts}
                        shopifyProducts={allShopifyProducts}
                        allGoogleSheetProductsRow={allGoogleSheetProductsRow}
                        magicMousse={true}
                        hideDots={true}
                        removeWidth={true}
                    />
                </Spacing>
            )}
        </>
    );
};

export default injectIntl(TripleRepairLanding);
