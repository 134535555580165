import React from 'react';
import { Accordion } from '../../../kenra-storybook/index';
import styled, { css } from 'styled-components';
import settings from './../../../kenra-storybook/global/settings';
const StFaqTitle = styled.div`
    display: flex;
    margin-left: auto;
    margin-right: auto;
    h2 {
        color: #000;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 181.818%;
        letter-spacing: 2px;
        text-transform: uppercase;
        @media (min-width: ${settings.bp.small.view}) {
            font-size: 20px;
            width: 70%;
            text-align: left;
            margin-left: 36px;
        }
    }
    @media (min-width: ${settings.bp.small.view}) {
        width: 30%;
    }
`;

const StFaqContainer = styled.div`
    margin-left: 17px;
    margin-right: 17px;
    display: flex;
    flex-direction: column;
    @media (min-width: ${settings.bp.small.view}) {
        flex-direction: row;
        margin-left: 100px;
        margin-right: 100px;
    }
`;

const StAccordionContainer = styled.div`
    display: flex;
    @media (min-width: ${settings.bp.small.view}) {
        width: 70%;
        max-width: 70%;
        min-width: 70%;
    }
`;

export default function FAQAccordion(props) {
    let { items, title } = props;

    return (
        <StFaqContainer>
            <StFaqTitle>
                <h2>{title}</h2>
            </StFaqTitle>
            <StAccordionContainer>
                <Accordion items={items} thin />
            </StAccordionContainer>
        </StFaqContainer>
    );
}
