import React from 'react'

import styled, { css } from 'styled-components'

import settings from '../../../kenra-storybook/global/settings'
import * as SharedStyles from '../../../kenra-storybook/global/SharedStyles'
const { StLinkMore } = SharedStyles
import CustomLink from 'components/Link'

const StMasonaryColumn = styled.section.attrs(props => ({
    className: 'StMasonaryColumn',
}))`
    display: flex;
    flex-direction: column;
    // margin: auto;
    padding-bottom: 24px;
    width: 100%;

    @media (min-width: ${settings.bp.medium.view}) {
        padding-left: 24px;
        padding-right: 24px;
        // margin-top: 42px;
    }

    h2 {
        color: #000;
        font-size: 22px;
        font-weight: 500;
        line-height: 1.87;
        letter-spacing: 0.08em;
        margin: 0 0 12px;
        text-transform: uppercase;
        text-align: center;

        @media (min-width: ${settings.bp.medium.view}) {
            line-height: 1.79;
            margin: 0 0 20px;
            margin-top: 36px;
            ${props =>
        props.smaller &&
        css`
                @media (min-width: ${settings.bp.small.view}) {
                    margin-top: 0px;
                }
                `};
        }
    }


    p {
        font-size: 16px;
        line-height: 1.5;
        margin: 0 0 16px;

        @media (min-width: ${settings.bp.medium.view}) {
            font-size: 18px;
            letter-spacing: 0.08em;
        }
    }

    *:last-child {
        margin-bottom: 0;
    }

    @media (min-width: ${settings.bp.small.view}) {
        margin-top: 24px;
    }

    // background-color: red;
    // border: 2px solid black;
    ${props =>
        props.smaller &&
        css`
        @media (max-width: ${settings.bp.small.view}) {
        h2 {
            font-size: 18.44px !important;
            font-style: normal;
            font-weight: 700;
            line-height: 120% !important;
            letter-spacing: 1.317px;
            text-transform: uppercase;
        }
        }
        `};
`

const StMasonaryItemImg = styled.div.attrs(props => ({
    className: 'StMasonaryItemImg',
}))`
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    @media (max-width: ${settings.bp.small.view}) {
        padding-left: 27px;
        padding-right: 27px;
    }

    img {
        max-width: 100%;
        object-fit: contain;
        @media (min-width: ${settings.bp.small.view}) {
            max-height: 900px;
            ${props =>
        props.smaller &&
        css`
                max-width: 80%;
                @media (min-width: ${settings.bp.small.view}) {
                  max-height: 600px;
                }
                `};
        }
    }
`

const StMasonaryItemInternalImg = styled.img.attrs(props => ({
    className: 'StMasonaryItemInternalImg',
}))`
    width: 100%;
`

const StMasonaryItemText = styled.div.attrs(props => ({
    className: 'StMasonaryItemText',
}))`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    color: #000;
    *:last-child {
        margin-bottom: 0;
    }
    

    h2 {
        font-size: 28px;
        font-weight:600;
        line-height: 33.6px;
        letter-spacing: 2px;
    }

    p {
        white-space: pre-line;

        ul {
            padding-inline-start: 20px;
        }

        li {
            text-align: left;
        }
    }
    padding: 20px 20px 20px;
    @media (min-width: ${settings.bp.small.view}) {
        // margin: auto;
        padding: 20px 20px 20px;
    }
`
const StMasonaryItemParagraphText = styled.div.attrs(props => ({
    className: 'StMasonaryItemParagraphText',
}))`
    white-space: pre-line;
    line-height: 1.5;
    margin: 0 0 16px;

    color: #000;

    text-align: center;
    font-size: 20px;
    letter-spacing: 0.1px;

    ul {
        padding-inline-start: 20px;
    }

    li {
        text-align: left;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        font-size: 20px;
        letter-spacing: 1px;
    }
`

const StMasonaryItemFootnoteText = styled.div.attrs(props => ({
    className: 'StMasonaryItemFootnoteText',
}))`
    white-space: pre-line;
    line-height: 1.5;
    margin: 0 0 16px;
    font-size: 13px;

    ul {
        padding-inline-start: 20px;
    }

    li {
        text-align: left;
    }

    @media (min-width: ${settings.bp.medium.view}) {
        font-size: 13px;
    }
`

const isOdd = n => {
    return n % 2
}

const isFirst = n => {
    return 0 === n
}

export default function MasonaryItem(props) {
    const { image, title, text, footnoteText, ctaLabel, link, index } = props

    return (
        <StMasonaryColumn smaller={index > 0}>
            {image && isFirst(index) && (
                <StMasonaryItemImg>
                    <StMasonaryItemInternalImg src={image} />
                </StMasonaryItemImg>
            )}

            <StMasonaryItemText>
                {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
                {text && (
                    <StMasonaryItemParagraphText
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                )}
                {footnoteText && (
                    <StMasonaryItemFootnoteText
                        dangerouslySetInnerHTML={{ __html: footnoteText }}
                    />
                )}
            </StMasonaryItemText>

            {image && !isFirst(index) && (
                <StMasonaryItemImg smaller={index > 0}>
                    <StMasonaryItemInternalImg src={image} />
                </StMasonaryItemImg>
            )}

            {link && ctaLabel && (
                <StLinkMore color='#FDF118' rounded invertTextColor center topMargin morePadding hoverColor={'#FFF87A'} responsiveFont>
                    <CustomLink target={"_blank"} style={{ 'letterSpacing': '0.659px' }} href={link}>
                        {ctaLabel}
                    </CustomLink>
                </StLinkMore>
            )}
        </StMasonaryColumn>
    )
}
